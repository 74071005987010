.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.sidebar-mapcontrol{
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 12px;
  z-index: 1 !important;
  padding: 6px;
}

.sidebar-left {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.sidebar-right {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 12px;
  /* background-color: #404040; */
  /* color: #ffffff; */
  z-index: 1 !important;
  padding: 6px;
  /* font-weight: bold; */
}

.mapboxgl-popup-content{
  padding: 0;
}
